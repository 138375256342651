import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Jinja`}</h2>
    <h3>{`Base64 asset encoder extension`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def init(app, settings):
    app.jinja_env.add_extension(AssetEncoderProcessor)

    return True

class AssetEncoderProcessor(Extension):
    def __init__(self, environment):
        super().__init__(environment)

    def preprocess(self, source, name, filename=None):
        return re.sub(
            r'base64_encode_asset\\(["\\'](.*?)["\\']\\)',
            lambda match: f"'{self.base64_encode_asset(match.group(1))}'",
            source
        )

    def base64_encode_asset(self, path):
        absolute_path = os.path.join(flask.current_app.root_path, path)
        mime, encoding = mimetypes.guess_type(absolute_path)

        with open(absolute_path, 'rb') as file:
            data = base64.b64encode(file.read()).decode('utf-8')

        return f'data:{mime};base64,{data}'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      